import { Link } from "react-router-dom";
import styled from "styled-components";
import bgImage from "../assets/images/clients/bg.png";
import client1 from "../assets/images/clients/client1.png";
import client2 from "../assets/images/clients/client2.png";
import client3 from "../assets/images/clients/client3.png";
import client4 from "../assets/images/clients/client4.png";
import client5 from "../assets/images/clients/client5.png";
import client6 from "../assets/images/clients/client6.png";
import arrow from "../assets/images/clients/arrow.svg";

const Clients = () => {
  return (
    <ClientStyle id="client-section">
      <div className="container">
        <div>
          <h1>Satisfied Clients</h1>
          <p>
            A leading provider of heating, cooling, and ventilation solutions
            for homes and businesses.
          </p>
        </div>
        <div className="clients">
          <div className="client">
            <img src={client1} alt="client" />
          </div>
          <div className="client">
            <img src={client2} alt="client" />
          </div>
          <div className="client">
            <img src={client3} alt="client" />
          </div>
          <div className="client">
            <img src={client3} alt="client" />
          </div>
        </div>

        <div className="clients">
          <div className="client">
            <img src={client4} alt="client" />
          </div>
          <div className="client">
            <img src={client5} alt="client" />
          </div>
          <div className="client">
            <img src={client6} alt="client" />
          </div>
          <div className="client">
            <img src={client3} alt="client" />
          </div>
        </div>
        <Link to="/clients">
          <button>
            More Clients <img src={arrow} alt="arrow" />
          </button>
        </Link>
      </div>
    </ClientStyle>
  );
};

export default Clients;

const ClientStyle = styled.div`
  background-image: url(${bgImage});
  /* min-height: 100vh; */
  background-size: cover;
  overflow: hidden;
  .container {
    max-width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 5rem 0;
    row-gap: 4rem;
  }
  h1 {
    font-weight: 700;
    font-size: 72px;
  }
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
    width: 40%;
  }
  .clients {
    display: flex;
    column-gap: 4rem;
    width: 100vw;
  }
  .client {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    min-width: 452px;
    max-width: 452px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.4s ease;
    &:hover {
      filter: drop-shadow(0px 0px 30px #ffd31a);
      transition: 0.4s ease;
    }
  }
  a {
    text-decoration: none;
  }
  button {
    width: fit-content;
    margin-top: 2rem;
    background-color: #d9190b;
    border: none;
    padding: 1.2rem 4rem;
    color: #ffffff;
    font-weight: 700;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.5rem;

    cursor: pointer;
    img {
      width: 40px;
    }
  }

  @media only screen and (max-width: 1400px) {
    .container {
      max-width: 1100px;
      row-gap: 2rem;
    }
    h1 {
      font-size: 62px;
    }
    p {
      width: 50%;
    }

    .clients {
      column-gap: 2rem;
    }
    .client {
      padding: 1rem;
      border-radius: 10px;
      min-width: 350px;
      max-width: 350px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 300px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .container {
      padding: 5rem 1rem;
      row-gap: 2rem;
    }
    h1 {
      font-weight: 700;
      font-size: 30px;
    }
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      width: 100%;
    }
    .clients {
      column-gap: 1rem;
    }
    .client {
      padding: 0.5rem;
      min-width: unset;
      max-width: 250px;
      img {
        width: 200px;
      }
    }
    button {
      margin-top: 1.5rem;
      padding: 1rem 2.5rem;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 0.5rem;
      img {
        width: 30px;
      }
    }
  }
`;
