import React, { useEffect } from "react";
import Banner from "../components/Banner";
import Services from "../components/Services";
import Clients from "../components/Clients";
import About from "../components/About";
import Team from "../components/Team";
import Contact from "../components/Contact";
import Location from "../components/Location";
import { useLocation } from "react-router-dom";

const Home = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Banner />
      <Services />
      <Clients />
      <About />
      <Team />
      <Contact />
      <Location />
    </>
  );
};

export default Home;
