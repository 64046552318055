import React from "react";
import styled from "styled-components";

const Location = () => {
  return (
    <Map>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d1825.874955993873!2d90.425009!3d23.756296!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1681057074905!5m2!1sen!2sbd"
        width="800"
        height="600"
        style={{ border: 0, width: "100%" }}
        // alowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </Map>
  );
};

export default Location;

const Map = styled.div`
  padding: 5rem 0;
`;
