import React from "react";
import styled from "styled-components";
import photo from "../assets/images/about/about.webp";
import arrow from "../assets/images/clients/arrow.svg";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <AboutStyle id="about-section">
      <div className="container">
        <div className="photo">
          <img src={photo} alt="HVAC solution service" />
        </div>
        <div className="text">
          <h1>About Us</h1>
          <div className="detail">
            <p>
              AirCon Engineering Ltd. is a company that provides high-quality
              air conditioning, dehumidification, fabrication, and construction
              services to various industries. Our corporate identity is centered
              around our commitment to help our customers thrive in a rapidly
              changing world.
            </p>
            <p>
              Our services include central air conditioning systems for all
              kinds of spaces, precision air conditioning systems for switch
              rooms and IT rooms, dehumidification and air conditioning systems
              for pharmaceuticals, garment industry, and other process
              industries, as well as fabrication and installation of various
              metal works, including ducting and piping, for factories.
            </p>
            <p>
              We also specialize in the design and construction of clean rooms,
              waste heat recovery systems from gas generators, cold rooms,
              evaporative cooling systems, and carbon dioxide sensor systems.
              Our goal is to provide customized solutions for our customers to
              meet their unique needs and requirements.
            </p>
          </div>
          <Link to="/story">
            <button>
              Learn More <img src={arrow} alt="arrow" />
            </button>
          </Link>
        </div>
      </div>
    </AboutStyle>
  );
};

export default About;

const AboutStyle = styled.div`
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 6rem;
    padding: 5rem 0;
    .photo {
      /* border: 1px solid yellow; */
    }
    img {
      width: 900px;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    /* border: 1px solid green; */
    margin-top: -1.6rem;
    h1 {
      font-weight: 700;
      font-size: 72px;
    }
    .detail {
      width: 80%;
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
    }
    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      text-align: justify;
      line-height: 30px;
    }
    a {
      text-decoration: none;
    }
    button {
      width: fit-content;
      background-color: #d9190b;
      border: none;
      padding: 1.2rem 4rem;
      color: #ffffff;
      font-weight: 700;
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 0.5rem;
      cursor: pointer;
      img {
        width: 40px;
      }
    }
  }

  @media only screen and (max-width: 1400px) {
    .container {
      column-gap: 3rem;
      padding: 5rem 0;
      .photo {
        /* border: 1px solid yellow; */
      }
      img {
        width: 650px;
      }
    }

    .text {
      row-gap: 1.5rem;
      /* border: 1px solid green; */
      margin-top: -1.6rem;
      h1 {
        font-weight: 700;
        font-size: 62px;
      }
      .detail {
        width: 90%;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
      }
      p {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        text-align: justify;
        line-height: 25px;
      }
      button {
        width: fit-content;
        background-color: #d9190b;
        border: none;
        padding: 1.2rem 4rem;
        color: #ffffff;
        font-weight: 700;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 0.5rem;
        img {
          width: 40px;
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .container {
      flex-direction: column;
      img {
        width: 100%;
      }
    }

    .text {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
      padding: 2rem 1rem;
      /* border: 1px solid green; */
      margin-top: -1rem;
      h1 {
        font-weight: 700;
        font-size: 30px;
      }
      .detail {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
      }
      p {
        font-weight: 300;
        font-size: 18px;
        line-height: 24px;
        text-align: unset;
        line-height: 30px;
      }
      button {
        padding: 1rem 3.5rem;
        font-size: 20px;
        img {
          width: 30px;
        }
      }
    }
  }
`;
