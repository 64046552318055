import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import client1 from "../assets/images/allclients/client1.png";
import client2 from "../assets/images/allclients/client2.png";
import client3 from "../assets/images/allclients/client3.png";
import client4 from "../assets/images/allclients/client4.png";
import client5 from "../assets/images/allclients/client5.png";
import client6 from "../assets/images/allclients/client6.png";
import client7 from "../assets/images/allclients/client7.jpg";
import client8 from "../assets/images/allclients/client8.png";
import client9 from "../assets/images/allclients/client9.jpeg";
import client10 from "../assets/images/allclients/client10.jpg";
import client11 from "../assets/images/allclients/client11.png";
import client12 from "../assets/images/allclients/client12.png";
import client13 from "../assets/images/allclients/client13.jpg";
import client14 from "../assets/images/allclients/client14.png";
import client15 from "../assets/images/allclients/client15.png";
import client16 from "../assets/images/allclients/client16.png";
import client17 from "../assets/images/allclients/client17.png";
import client18 from "../assets/images/allclients/client18.png";
import client19 from "../assets/images/allclients/client19.jpg";
import client20 from "../assets/images/allclients/client20.jpg";
import client21 from "../assets/images/allclients/client21.jpg";
import client22 from "../assets/images/allclients/client22.jpg";
import client23 from "../assets/images/allclients/client23.png";
import client24 from "../assets/images/allclients/client24.jpg";
import client25 from "../assets/images/allclients/client25.png";
import client26 from "../assets/images/allclients/client26.png";
import client27 from "../assets/images/allclients/client27.png";
import client28 from "../assets/images/allclients/client28.jpg";
import client29 from "../assets/images/allclients/client29.jpg";
import client30 from "../assets/images/allclients/client30.png";
import client31 from "../assets/images/allclients/client31.png";
import client32 from "../assets/images/allclients/client32.png";
import client33 from "../assets/images/allclients/client33.jpg";
import client34 from "../assets/images/allclients/client34.jpg";

const AllClients = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <AllClientStyle>
      <div className="container">
        <h1>Clients</h1>
        <div className="clients">
          <div className="client">
            <img src={client1} alt="client" />
            <p>
              Multifabs Ltd. offers all sorts of knitted apparel of latest
              design and quality
            </p>
          </div>
          <div className="client">
            <img src={client2} alt="client" />
            <p>
              Lyric Industries(pvt.) Limited is among one of the most successful
              garment manufacturers in Bangladesh; a country with great
              potential in the field of garment-manufacturing. It started its
              journey in the year 2001.
            </p>
          </div>
          <div className="client">
            <img src={client3} alt="client" />
            <p>
              Rahmat Group is a vertically integrated textile manufacturer in
              Bangladesh.
            </p>
          </div>
          <div className="client">
            <img src={client4} alt="client" />
            <p>
              Plot-36, Road-46, Gulshan-2 North C/A, Dhaka- 1212, Bangladesh.
              +880-2-9882240, 9884649, 017238-310900
            </p>
          </div>
          <div className="client">
            <img src={client5} alt="client" />
            <p>
              Formed in 1984 by Dr. Athar Uddin two of his friends in Tejgaon.
              Later in the year 1987, he bought his first individual ownership
              factory Sharaka Ind. and the company has grown exponentially so
              far.
            </p>
          </div>
          <div className="client">
            <img src={client6} alt="client" />
            <p>
              Fakir Apparels and Armour Polymer Ltd. join forces to produce
              medical grade PPE gear easily available for every healthcare
              provider in the country.
            </p>
          </div>
          <div className="client">
            <img src={client7} alt="client" />
            <p>
              Mohammadi Group is a renowned conglomerate in Bangladeshstarted
              its operations in 1986 in the garments industry with merely 52
              workers. Today it employees over 10,000.
            </p>
          </div>
          <div className="client">
            <img src={client8} alt="client" />
            <p>
              A LEED GOLD certified sweater manufacturer. Uttarkhan Uttara, 1230
              Harbaid, Dhaka Division, Bangladesh.
            </p>
          </div>
          <div className="client">
            <img src={client9} alt="client" />
            <p>
              Hamza Clothing is Top Quality Leather, Motorbike and Sportswear
              Manufacturing Company.
            </p>
          </div>
          <div className="client">
            <img src={client10} alt="client" />
            <p>
              A Plus Group started its journey, aiming to build state of the art
              facility in the ready-made garments industry, with the
              establishment of A Plus Industries Limited, a 100% export oriented
              ready-made garments manufacturer, in 1996.
            </p>
          </div>
          <div className="client">
            <img src={client11} alt="client" />
            <p>
              Riviera Group is a group of companies. Mr. Mir Nazrul Islam is the
              founder of this Group which is establish in 1997. Main business
              focus of this group are buying service, manufacturing of all kinds
              of light knit apparel using its vertical set up.
            </p>
          </div>
          <div className="client">
            <img src={client12} alt="client" />
            <p>
              Solar Group. "Solar" House 14, Kachimet, Amravati Road Nagpur
              440023. (+91)712-6634555, (+91)712-6634550
            </p>
          </div>
          <div className="client">
            <img src={client13} alt="client" />
            <p>
              Dresden Textiles Ltd. is a trusted name as &#39;An Export Oriented
              Knit Garments Manufacturer&#39; in Garments Sectors&rsquo;. It was
              established in 2007
            </p>
          </div>
          <div className="client">
            <img src={client14} alt="client" />
            <p>
              Bangladesh Ordnance Factories (BOF) is the largest industrial
              supplier of the Bangladesh Army. Situated in Gazipur, it produces
              arms, ammunition, and equipment for the Bangladesh Armed Forces.
            </p>
          </div>
          <div className="client">
            <img src={client15} alt="client" />
            <p>
              Universal College Bangladesh (UCB) is Bangladesh&rsquo;s premier
              transnational education institute and Monash College&rsquo;s
              exclusive partner in providing a pathway to Monash University
              degrees from Bangladesh
            </p>
          </div>
          <div className="client">
            <img src={client16} alt="client" />
            <p>
              Desh Pharmaceuticals (Pvt). Ltd. is a GMP CERTIFIED leading
              pharmaceutical company in Bangladesh established in the year 1988
            </p>
          </div>
          <div className="client">
            <img src={client17} alt="client" />
            <p>
              PHP Family is now envisioning set up Country&rsquo;s first
              Integrated Steel Plant to produce both Semi Finished and Finished
              Steel in the form of Flat and Long Products from Iron Ore.
            </p>
          </div>
          <div className="client">
            <img src={client18} alt="client" />
            <p>
              That the captioned hotel is the most modern facilitated
              international standard hotel and holds a prime location and
              bumming area in Khulna City.
            </p>
          </div>
          <div className="client">
            <img src={client19} alt="client" />
            <p>
              Ministry of Science and Technology, Government of the People&#39;s
              Republic of Bangladesh
            </p>
          </div>
          <div className="client">
            <img src={client20} alt="client" />
            <p>
              BURO Bangladesh stands for Basic Unit for Resources and
              Opportunities of Bangladesh
            </p>
          </div>
          <div className="client">
            <img src={client21} alt="client" />
            <p>Dhaka Gladiators</p>
          </div>
          <div className="client">
            <img src={client22} alt="client" />
            <p>
              Send money through phone, instant &middot; Recharge mobile,
              anytime &middot; Pay education fees, no queue &middot; Pay utility
              bills, no hassle &middot; SureCash app, it&#39;s simple &amp;
              easy.
            </p>
          </div>
          <div className="client">
            <img src={client23} alt="client" />
            <p>
              bKash is a mobile financial service in Bangladesh operating under
              the authority of Bangladesh Bank as a subsidiary of BRAC Bank
              Limited.
            </p>
          </div>
          <div className="client">
            <img src={client24} alt="client" />
            <p>
              Industrial and Infrastructure Development Finance Company Limited
            </p>
          </div>
          <div className="client">
            <img src={client25} alt="client" />
            <p>
              On a never ending quest for excellence, Dnet innovated and started
              off various ... 4/8 Humayun Road, Block-B, Mohammadpur, Dhaka
              1207, Bangladesh.
            </p>
          </div>
          <div className="client">
            <img src={client26} alt="client" />
            <p>
              Woori Bank is a Korean multinational bank headquartered in Seoul.
            </p>
          </div>
          <div className="client">
            <img src={client27} alt="client" />
            <p>Nurjahan Group. Shipping Companies. Bangladesh</p>
          </div>
          <div className="client">
            <img src={client28} alt="client" />
            <p>
              The school was established in 1974 with the mission of providing
              each student a diverse education in a safe supportive environment
              that promotes self discipline, motivation and excellence in
              learning.
            </p>
          </div>
          <div className="client">
            <img src={client29} alt="client" />
            <p>
              The University of Information Technology &amp; Sciences is a
              private university in Baridhara, Dhaka, Bangladesh. It&#39;s the
              first IT based private university in Bangladesh.
            </p>
          </div>
          <div className="client">
            <img src={client30} alt="client" />
            <p>Liwayway Food Bangladesh Pvt.&nbsp; Limited.</p>
          </div>
          <div className="client">
            <img src={client31} alt="client" />
            <p>
              Western Marine Shipyard Limited is a public listed shipbuilding
              company based in Chattogram, Bangladesh.
            </p>
          </div>
          <div className="client">
            <img src={client32} alt="client" />
            <p>
              DMFR Molecular Lab is a World-class Diagnostic Healthcare Centre.
            </p>
          </div>
          <div className="client">
            <img src={client33} alt="client" />
            <p>
              Bengal Art Lounge is located at 60 Gulshan Avenue, Dhaka - 1212.
              Besides this museum, there are eighteen more museums listed in
              Dhaka. There's also thirty-six attractions listed in this district
              in other categories.
            </p>
          </div>
          <div className="client">
            <img src={client34} alt="client" />
            <p>
              TotiCell, an Advanced Regenerative Healthcare Centre for Unmet
              Medical Needs.
            </p>
          </div>
        </div>
      </div>
    </AllClientStyle>
  );
};

export default AllClients;

const AllClientStyle = styled.div`
  padding-bottom: 5rem;
  .container {
    max-width: 1200px;
    margin: auto;
    padding: 5rem 0;
    h1 {
      font-size: 72px;
      font-weight: 700;
      padding-bottom: 4rem;
    }
    .clients {
      display: flex;
      gap: 4rem;
      flex-wrap: wrap;

      .client:nth-child(1) {
        img {
          width: 350px;
        }
      }

      .client:nth-child(2) {
        img {
          width: 100px;
          height: 120px;
        }
      }

      .client:nth-child(3) {
        img {
          width: 350px;
        }
      }

      .client:nth-child(4) {
        img {
          width: 120px;
        }
      }

      .client:nth-child(5) {
        img {
          width: 150px;
        }
      }

      .client:nth-child(6) {
        img {
          width: 300px;
        }
      }

      .client:nth-child(7) {
        img {
          width: 120px;
        }
      }

      .client:nth-child(8) {
        img {
          width: 350px;
        }
      }
      .client:nth-child(9) {
        img {
          width: 100px;
        }
      }
      .client:nth-child(10) {
        img {
          width: 200px;
        }
      }
      .client:nth-child(11) {
        img {
          width: 200px;
        }
      }
      .client:nth-child(12) {
        img {
          width: 100px;
        }
      }
      .client:nth-child(13) {
        img {
          width: 250px;
        }
      }
      .client:nth-child(14) {
        img {
          width: 120px;
        }
      }
      .client:nth-child(15) {
        img {
          width: 220px;
        }
      }

      .client:nth-child(16) {
        img {
          width: 220px;
        }
      }
      .client:nth-child(17) {
        img {
          width: 200px;
        }
      }
      .client:nth-child(18) {
        img {
          width: 200px;
        }
      }
      .client:nth-child(19) {
        img {
          width: 200px;
        }
      }
      .client:nth-child(20) {
        img {
          width: 120px;
        }
      }
      .client:nth-child(21) {
        img {
          width: 120px;
        }
      }
      .client:nth-child(22) {
        img {
          width: 180px;
        }
      }
      .client:nth-child(23) {
        img {
          width: 100px;
        }
      }
      .client:nth-child(24) {
        img {
          width: 120px;
        }
      }
      .client:nth-child(25) {
        img {
          width: 200px;
        }
      }
      .client:nth-child(26) {
        img {
          width: 150px;
        }
      }
      .client:nth-child(27) {
        img {
          width: 250px;
        }
      }
      .client:nth-child(28) {
        img {
          width: 180px;
        }
      }
      .client:nth-child(29) {
        img {
          width: 120px;
        }
      }
      .client:nth-child(30) {
        img {
          width: 220px;
        }
      }
      .client:nth-child(31) {
        img {
          width: 250px;
        }
      }
      .client:nth-child(32) {
        img {
          width: 220px;
        }
      }
      .client:nth-child(33) {
        img {
          width: 120px;
        }
      }
      .client:nth-child(34) {
        img {
          width: 120px;
        }
      }
    }

    .client {
      background-color: #ffffff;
      color: #000000;
      max-width: 520px;
      min-width: 520px;
      min-height: 350px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 2rem;
      border-radius: 10px;
      p {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .container {
      padding: 5rem 1rem;
      h1 {
        font-size: 30px;
      }
    }
    .clients {
      .client:nth-child(1) {
        img {
          max-width: 250px;
        }
      }
      .client:nth-child(2) {
        img {
          max-width: 80px;
          height: 120px;
        }
      }

      .client:nth-child(3) {
        img {
          max-width: 250px;
        }
      }

      .client:nth-child(6) {
        img {
          max-width: 250px;
        }
      }
      .client:nth-child(8) {
        img {
          max-width: 250px;
        }
      }
      .client:nth-child(13) {
        img {
          max-width: 200px;
        }
      }

      .client {
        min-width: 100%;
        min-height: 380px;
      }
    }
  }
`;
