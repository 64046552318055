import React from "react";
import styled from "styled-components";

const Contact = () => {
  return (
    <ContactStyle id="contact-section">
      <div className="container">
        <h1>Contact Us</h1>
        <div className="form">
          <input type="text" placeholder="Name" />
          <input type="text" placeholder="Email" />
          <input type="text" placeholder="Phone" />
          <textarea placeholder="Message"></textarea>

          <button>Send Message</button>
        </div>
      </div>
    </ContactStyle>
  );
};

export default Contact;

const ContactStyle = styled.div`
  min-height: 80vh;
  .container {
    display: flex;
    flex-direction: column;
    max-width: 1400px;
    margin: auto;
    padding: 5rem 0;
    row-gap: 2rem;
    h1 {
      font-weight: 700;
      font-size: 72px;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
  }

  input {
    background-color: transparent;
    border: 1px solid #ffffff;
    height: 75px;
    width: 70%;
    color: #ffffff;
    font-size: 28px;
    padding: 1.5rem;
  }
  textarea {
    background-color: transparent;
    border: 1px solid #ffffff;
    width: 70%;

    font-size: 28px;
    padding: 1.5rem;
    color: #ffffff;
    height: 200px;
  }
  button {
    width: fit-content;
    background-color: #d9190b;
    border: none;
    padding: 1.2rem 4rem;
    color: #ffffff;
    font-weight: 700;
    font-size: 24px;

    cursor: pointer;
  }

  @media only screen and (max-width: 1400px) {
    .container {
      max-width: 1100px;
      padding: 5rem 0;
      row-gap: 2rem;
      h1 {
        font-weight: 700;
        font-size: 62px;
      }
    }

    .form {
      row-gap: 1.5rem;
    }

    input {
      background-color: transparent;
      border: 1px solid #ffffff;
      height: 55px;
      width: 70%;
      color: #ffffff;
      font-size: 20px;
      padding: 1.5rem;
    }

    textarea {
      width: 70%;

      font-size: 20px;
      padding: 1.5rem;
      color: #ffffff;
      height: 150px;
    }

    button {
      padding: 1.2rem 4rem;
      color: #ffffff;
      font-weight: 700;
      font-size: 24px;
    }
  }

  @media only screen and (max-width: 600px) {
    .container {
      row-gap: 3rem;
      padding: 5rem 1rem;
      h1 {
        font-size: 30px;
      }
    }

    input {
      height: 60px;
      width: 100%;
      font-size: 20px;
      padding: 1rem;
      margin: unset;
    }
    textarea {
      width: 100%;
      margin: unset;
      font-size: 20px;
      padding: 1rem;
      color: #ffffff;
      height: 150px;
    }

    button {
      width: 100%;
      background-color: #d9190b;
      border: none;
      padding: 1rem 3.5rem;
      color: #ffffff;
      font-weight: 700;
      font-size: 20px;
      margin-left: unset;
    }
  }
`;
