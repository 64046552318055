import React from "react";
import styled from "styled-components";
import strategyImage from "../assets/images/story/strategy.svg";

const Strategy = () => {
  return (
    <StrategyStyle>
      <div className="container">
        <h1>Strategies</h1>
        <div className="text-container">
          <img src={strategyImage} alt="strategy" />
          <div className="text">
            <p>
              Product positioning will be high quality mid pricing strategy
              augmented by optimal level of after sales service.
            </p>
            <p>
              We will build long-term partnerships with our customers.With their
              support, we can maximize the potential of our traditional
              business, througha combination of enhanced quality of service,
              creative marketing, innovative pricingand cost efficiency. At the
              same time, we'll pursue profitable growth by migrating
              ourcustomers to new wave products and services, such as ICT
              (informationcommunications technology), broadband, mobility and
              managed services.
            </p>
          </div>
        </div>
      </div>
    </StrategyStyle>
  );
};

export default Strategy;

const StrategyStyle = styled.div`
  padding-bottom: 5rem;
  .container {
    max-width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    row-gap: 5rem;
    padding: 5rem 0;
    h1 {
      font-weight: 700;
      font-size: 72px;
    }
  }
  .text-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  .text {
    width: 55%;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 35px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .container {
      max-width: 1100px;
      row-gap: 2.5rem;
      h1 {
        font-size: 62px;
      }
    }

    .text {
      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 35px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .container {
      padding: 5rem 1rem;
      h1 {
        font-weight: 700;
        font-size: 30px;
      }
    }
    .text-container {
      flex-direction: column;
      img {
        width: 50%;
        padding-bottom: 2rem;
      }
    }
    .text {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 2rem;

      p {
        font-weight: 400;
        font-size: 18px;
      }
    }
  }
`;
