import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    *,
    *::after,
    *::before {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    
    body{
        font-family: "Poppins", sans-serif;
        background-color: #000000;
        color:#ffffff;
    }
   
`;

export default GlobalStyle;
