import React, { useEffect, useState } from "react";
import styled from "styled-components";
import project1 from "../assets/images/works/green-project.webp";
import project2 from "../assets/images/works/works.webp";
import project3 from "../assets/images/works/cooler.webp";
import arrow from "../assets/images/works/arrow.svg";
import { useLocation } from "react-router-dom";

const Works = () => {
  const [showGreen, setShowGreen] = useState(true);
  const [showWork, setShowWork] = useState(false);
  const [showCooler, setShowCooler] = useState(false);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <WorkStyle>
      <div className="container">
        <h1>Works & Successfull Projects</h1>
        <div className="works">
          <div
            className="work"
            onClick={() => {
              setShowGreen(!showGreen);
              setShowWork(false);
              setShowCooler(false);
            }}
          >
            <h1>Green Certified Projects</h1>
            <img src={project1} alt="green projects" />
            <img className="arrow" src={arrow} alt="arrow" />
            <div className="overlay"></div>
          </div>

          {showGreen && (
            <div className="detail">
              <div>
                <h2>Multifabs Ltd (LEED PLATINUM)</h2>
                <p>Multifabs Ltd (LEED PLATINUM)</p>
              </div>

              <div>
                <h2>Lyric Industry Ltd (LEED GOLD)</h2>
                <p>
                  Supply, installation and commissioning of VRF Air-condition,
                  Evaporative Cooler, Ventilation and CO2 system
                </p>
              </div>

              <div>
                <h2>RAHAMAT GROUP (LEED GOLD)</h2>
                <p>
                  Supply, installation and commissioning of VRF Air-condition,
                  Evaporative Cooler, Ventilation and CO2 system
                </p>
              </div>

              <div>
                <h2>SIDKO GROUP (LEED PLATINUM)</h2>
                <p>
                  Supply, installation and commissioning of VRF Air-condition,
                  Evaporative Cooler, Ventilation and CO2 system
                </p>
              </div>
              <div>
                <h2>AZMAT GROUP (LEED GOLD)</h2>
                <p>
                  Supply, installation and commissioning of VRF Air-condition,
                  Evaporative Cooler, Ventilation and CO2 system
                </p>
              </div>

              <div>
                <h2>FAKIR APPERALS LTD (Proposed LEED Gold)</h2>
                <p>
                  Supply, installation and commissioning of VRF Air-condition,
                  Evaporative Cooler and Ventilation system
                </p>
              </div>

              <div>
                <h2>Mohammodi Group (LEED Gold)</h2>
                <p>
                  Supply, installation and commissioning of VRF Air-condition,
                  Evaporative Cooler, Ventilation and CO2 system
                </p>
              </div>

              <div>
                <h2>JFK Fashion Ltd (LEED Silver)</h2>
                <p>
                  Supply, installation and commissioning of Ventilation system
                </p>
              </div>

              <div>
                <h2>Hamza Clothing Ltd (LEED Gold)</h2>
                <p>
                  Supply, installation and commissioning of Ventilation system
                </p>
              </div>

              <div>
                <h2>A Plus Garments Ltd (LEED Silver) Running</h2>
                <p>
                  Supply, installation and commissioning of Evaporative Cooler &
                  Ventilation system
                </p>
              </div>

              <div>
                <h2>Riveria Knit Composite LTD(LEED Silver)- Running</h2>
                <p>
                  Supply, installation and commissioning of VRF Air-condition,
                  Evaporative Cooler, Ventilation and CO2 system
                </p>
              </div>

              <div>
                <h2>Solar Group(LEED Gold)- Running</h2>
                <p>
                  Supply, installation and commissioning of VRF Air-condition,
                  Evaporative Cooler, Ventilation and CO2 system
                </p>
              </div>

              <div>
                <h2>Dreesden Textiles Ltd</h2>
                <p>
                  Supply, installation and commissioning of VRF Air-condition,
                  Ventilation and CO2 system
                </p>
              </div>
            </div>
          )}

          <div
            className="work"
            onClick={() => {
              setShowWork(!showWork);
              setShowGreen(false);
              setShowCooler(false);
            }}
          >
            <h1>Major Works</h1>
            <img src={project2} alt="green projects" />
            <img className="arrow" src={arrow} alt="arrow" />
            <div className="overlay"></div>
          </div>

          {showWork && (
            <div className="detail" id="green">
              <div>
                <h2>BURO Bangladesh (Hotel Project)- Tangail</h2>
                <p>
                  Supply, installation, testing and commissioning of V.R.F Air
                  conditioning system. Capacity - 1500 TR
                </p>
              </div>

              <div>
                <h2>DPS Sunvalley, STS Group (Glenrich Int. School)</h2>
                <p>
                  Supply, installation, testing and commissioning of Central Air
                  conditioning system with mechanical ventilation Capacity -
                  1200 TR (Running)
                </p>
              </div>

              <div>
                <h2>Hotel City Inn Ltd., Khulna</h2>
                <p>
                  Supply, installation, testing and commissioning of
                  air-conditioning system. Capacity - 760 TR
                </p>
              </div>

              <div>
                <h2>
                  Institute of Bioequivalence Studies and Pharmaceutical
                  Sciences' in the campus of BCSIR, Dhanmondi, Dhaka
                </h2>
                <p>
                  Supply and installation work of air conditioning system (HVAC
                  and Chiller Systems) including ancillary works Capacity- 400
                  TR
                </p>
              </div>
              <div>
                <h2>
                  Designated Reference Institute for Chemical Measurements
                  (DRiCM), BCSIR- (Science Lab)
                </h2>
                <p>
                  Supply, installation, testing and commissioning of V.R.F Air
                  conditioning system. Capacity - 700 TR
                </p>
              </div>

              <div>
                <h2>Prince Jacqurd, Gazipura 27, Gazipur</h2>
                <p>
                  Installation, testing and commissioning of the Central
                  Airconditioning Water Cooled Chiller system. Capacity - 250TR
                </p>
              </div>

              <div>
                <h2>Taranibash Shopping complex, Feni</h2>
                <p>Air conditioning system Capacity - 470 TR</p>
              </div>

              <div>
                <h2>UITS CAMPUS(PHP Group), Badda, Dhaka</h2>
                <p>
                  Supply, installation, testing and commissioning of air
                  conditioning system. (459 TR)
                </p>
              </div>

              <div>
                <h2>BURO Bangladesh (Hotel Project), Cumilla</h2>
                <p>
                  Supply, installation, testing and commissioning of V.R.F Air
                  conditioning system. Capacity - 550 TR
                </p>
              </div>

              <div>
                <h2>Muslim Institute, Chittagong (PWD)</h2>
                <p>
                  Installation, testing and commissioning of the Central
                  Airconditioning Water Cooled Magnetic Bearing Chiller system.
                  Capacity - 1350TR (Running)
                </p>
              </div>

              <div>
                <h2>Checkpoint Systems BD Ltd, Adamji EPZ</h2>
                <p>
                  Supply, installation, testing and commissioning of Chilled
                  water Central Air conditioning system with mechanical
                  ventilation Capacity - 570 TR (Running)
                </p>
              </div>

              <div>
                <h2>Moinamoti Super Complex, Comilla Cantonment, Comilla</h2>
                <p>Ducted split Air conditioning system Capacity - 224 TR</p>
              </div>

              <div>
                <h2>IIDFC Ltd. (Gulshan-1)</h2>
                <p>
                  Supply, installation, testing and commissioning of air
                  conditioning system. Capacity - 55 TR
                </p>
              </div>

              <div>
                <h2>dNet, Mohammadpur</h2>
                <p>
                  Supply, installation, testing and commissioning of air
                  conditioning system. Capacity - 100 TR
                </p>
              </div>

              <div>
                <h2>Woori bank, Naranganj branch</h2>
                <p>
                  Supply, installation, testing and commissioning of air
                  conditioning system. Capacity - 70 TR
                </p>
              </div>

              <div>
                <h2>PHP Steel Complex ECR, Chittagong</h2>
                <p>
                  Ducted Air-cooled Package airconditioning system for the ECR
                  Capacity - 150 TR
                </p>
              </div>

              <div>
                <h2>Sakura Dyeing and Garments ltd.</h2>
                <p>
                  Supply, installation, testing and commissioning of air
                  conditioning system. Capacity - 80 TR
                </p>
              </div>

              <div>
                <h2>Nurjahan Spice Ltd.</h2>
                <p>
                  Supply, installation, testing and commissioning of the
                  Roof-top Ducted Air-cooled Packaged air
                </p>
              </div>

              <div>
                <h2>Sagarika, Chittagong</h2>
                <p>Conditioning system. Capacity - 50 TR</p>
              </div>

              <div>
                <h2>Nurjahan Group Head Office, Khatunjang, Chittagong</h2>
                <p>Ducted split type Air conditionner 120 TR</p>
              </div>

              <div>
                <h2>
                  Business class Passenger's Lounge at Shah-Jalal International
                  Airport, Dhaka
                </h2>
                <p>
                  Supply, installation, testing and commissioning of air
                  conditioning system. Capacity - 150 TR
                </p>
              </div>

              <div>
                <h2>Balisera Vally Club, Finlay, Srimangal</h2>
                <p>
                  Supply, installation, testing and commissioning of air
                  conditioning system. Capacity - 170 TR
                </p>
              </div>

              <div>
                <h2>Tiny-tots School Building Mohammadpur, Dhaka</h2>
                <p>
                  Supply, installation, testing and commissioning of air
                  conditioning system. Capacity - 50 TR
                </p>
              </div>

              <div>
                <h2>Liwayway Food Bangladesh Pvt Limited.</h2>
                <p>
                  Supply, installation, testing and commissioning of V.R.F Air
                  conditioning system. Capacity - 65 TR
                </p>
              </div>

              <div>
                <h2>Cox-Bazaar International Stadium</h2>
                <p>
                  Supply, installation, testing and commissioning of
                  air-conditioning system. Capacity - 260 TR
                </p>
              </div>

              <div>
                <h2>Corporate office of Mostafa Group Of Industries</h2>
                <p>
                  Supply, installation, testing and commissioning of the Split
                  air conditioning system. Capacity - 180 TR
                </p>
              </div>

              <div>
                <h2>Western Marine corporate Office, Fokirhat, Chittagong</h2>
                <p>
                  Supply, installation, testing and commissioning of V.R.F Air
                  conditioning system. Capacity - 80 TR
                </p>
              </div>

              <div>
                <h2>UNITED APPERALS</h2>
                <p>
                  Supply, installation, testing and commissioning of V.R.F Air
                  conditioning system. Capacity - 40 TR
                </p>
              </div>

              <div>
                <h2>CROSSLINE KNITINGWEAR</h2>
                <p>
                  Supply, installation, testing and commissioning of V.R.F Air
                  conditioning system. Capacity - 150 TR
                </p>
              </div>

              <div>
                <h2>DMFR MOLECULER LAB, DHAK</h2>
                <p>
                  Supply, installation, testing and commissioning of V.R.F Air
                  conditioning system. Capacity - 150 TR
                </p>
              </div>

              <div>
                <h2>VM HEALTH, DHAKA</h2>
                <p>
                  Supply, installation, testing and commissioning of V.R.F Air
                  conditioning system. Capacity - 80 TR
                </p>
              </div>

              <div>
                <h2>DOE, MINSTRY OF ENVIOURMENT.</h2>
                <p>
                  Supply, installation, testing and commissioning of V.R.F Air
                  conditioning system. Capacity - 45 TR
                </p>
              </div>

              <div>
                <h2>SHAMIM RESIDENCE, DHAKA</h2>
                <p>
                  Supply, installation, testing and commissioning of V.R.F Air
                  conditioning system. Capacity - 45 TR
                </p>
              </div>

              <div>
                <h2>BISHOP HOUSE, PATHORGHATA CATHOLIC CHURCH, CHITTAGONG</h2>
                <p>
                  Supply, installation, testing and commissioning of V.R.F Air
                  conditioning system. Capacity - 70 TR
                </p>
              </div>

              <div>
                <h2>KHAN RESIDENCE, KHULNA</h2>
                <p>
                  Supply, installation, testing and commissioning of V.R.F Air
                  conditioning system. Capacity - 40 TR
                </p>
              </div>

              <div>
                <h2>Bengal Art Lounge, Karwan Bazar, Dhaka</h2>
                <p>
                  Installation, testing and commissioning of the Central
                  Airconditioning Water Cooled Chiller system. Capacity - 115TR
                </p>
              </div>

              <div>
                <h2>BURO Bangladesh, Baridhara</h2>
                <p>
                  Supply, installation, testing and commissioning of V.R.F Air
                  conditioning system. Capacity - 100 TR
                </p>
              </div>

              <div>
                <h2>Bangladesh Ordinance Factory, Gazipur, Dhaka</h2>
                <p>
                  Installation, testing and commissioning of the Central
                  Air-conditioning air Cooled Chiller system. 250 TR
                </p>
              </div>

              <div>
                <h2>UCB, STS Group</h2>
                <p>
                  Installation, testing and commissioning of the Central
                  Air-conditioning VRF system. 250 TR
                </p>
              </div>

              <div>
                <h2>TotiCell, Dhanmondi</h2>
                <p>
                  Supply, installation, testing and commissioning of V.R.F Air
                  conditioning system. Capacity - 50 TR
                </p>
              </div>

              <div>
                <h2>
                  GGTC Project, Khagan, Savar ( Brac), Consortium with Shristi
                  Engineering
                </h2>
                <p>
                  Air-cooled ducted Package airconditioning system for the Hall
                  room. Capacity - 120 TR
                </p>
              </div>

              <div>
                <h2>LAZZ convension Center, Savar</h2>
                <p>
                  Supply, installation, testing and commissioning of air
                  conditioning system Capacity - 60 TR
                </p>
              </div>
              {/* jasdlfjk */}
            </div>
          )}

          <div
            className="work"
            onClick={() => {
              setShowCooler(!showCooler);
              setShowWork(false);
              setShowGreen(false);
            }}
          >
            <h1>Cooler & ventilation & CO2 Sensor</h1>
            <img src={project3} alt="cooler projects" />
            <img className="arrow" src={arrow} alt="arrow" />
            <div className="overlay"></div>
          </div>

          {showCooler && (
            <div className="detail" id="cooler">
              <div>
                <h2>PHP Petrorifainary Ltd</h2>
                <p>
                  Design, supply, installation and commissioning of Ventilation
                  and CO2 system
                </p>
              </div>

              <div>
                <h2>PHP Steel Complex Ltd</h2>
                <p>
                  Design, supply, installation and commissioning of Ventilation
                  and CO2 system
                </p>
              </div>

              <div>
                <h2>PHP Spining Mills Ltd</h2>
                <p>
                  Design, supply, installation and commissioning of Evaporative
                  Cooler, Ventilation and CO2 system
                </p>
              </div>

              <div>
                <h2>Nurjahan Super oil Ltd</h2>
                <p>
                  Design, supply, installation and commissioning of Ventilation
                  and CO2 system
                </p>
              </div>
              <div>
                <h2>Zamia Super oil Ltd</h2>
                <p>
                  Design, supply, installation and commissioning of Ventilation
                  system
                </p>
              </div>

              <div>
                <h2>Tasmin Flour MILLS Ltd</h2>
                <p>
                  Design, supply, installation and commissioning of Ventilation
                  and CO2 system
                </p>
              </div>

              <div>
                <h2>Nurjahan Spices Ltd</h2>
                <p>
                  Design, supply, installation and commissioning of Evaporative
                  Cooler, Ventilation and CO2 system
                </p>
              </div>

              <div>
                <h2>Sakura Dying Ltd</h2>
                <p>
                  Design, supply, installation and commissioning of Ventilation
                  and Evaporative Cooler system
                </p>
              </div>

              <div>
                <h2>Western Marine Shippered Ltd</h2>
                <p>
                  Design, supply, installation and commissioning of Ventilation
                  and CO2 system
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </WorkStyle>
  );
};

export default Works;

const WorkStyle = styled.div`
  padding-bottom: 5rem;
  .container {
    max-width: 1400px;
    margin: auto;
    min-height: 80vh;
    padding: 5rem 0;
    h1 {
      font-weight: 700;
      font-size: 72px;
    }
  }

  .works {
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }

  .work {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 10px;
    color: #000000;
    align-items: center;
    width: 100%;
    max-height: 120px;
    overflow: hidden;
    position: relative;
    /* z-index: 1; */
    cursor: pointer;

    img {
      width: 50%;
      z-index: 2;
      &:last-of-type {
        z-index: 6;
      }
    }

    h1 {
      font-size: 30px;
      font-weight: 500;
      padding-left: 2rem;
    }
    .overlay {
      z-index: 2;
      position: absolute;
      background: linear-gradient(
        90deg,
        #ffffff 0%,
        rgba(255, 254, 254, 0) 100%
      );
      top: 0;
      right: 0;
      bottom: 0;
      width: 50%;
      height: 100%;
    }
    &:hover {
      filter: drop-shadow(0px 0px 30px #c9f4aa96);
    }
    &:nth-child(1) {
      h1 {
        color: #1d987d;
      }
    }
    &:nth-child(2) {
      h1 {
        color: #e08f62;
      }
    }
    &:nth-child(3) {
      h1 {
        color: #383e56;
      }
    }
  }

  .arrow {
    position: absolute;
    right: 10px;
    max-width: 100px;
    /* z-index: 100; */
    /* backdrop-filter: blur(10px); */
    border-radius: 10px;
    padding: 0.4rem;
    cursor: pointer;
  }

  .detail {
    background-color: rgba(255, 255, 255, 0.17);
    border-radius: 10px;
    width: 100%;
    margin-top: 1rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 4rem;
    div {
      padding-bottom: 4rem;
      border-bottom: 1px solid #ffffff50;
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
      h2 {
        font-size: 30px;
        font-weight: 500;
        color: #c9f4aa;
      }
      p {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  #green {
    div {
      h2 {
        color: #febe8c;
      }
    }
  }

  #cooler {
    div {
      h2 {
        color: #a6b1e1;
      }
    }
  }

  @media only screen and (max-width: 1400px) {
    .container {
      max-width: 1100px;
      h1 {
        font-size: 62px;
      }
    }
    .work {
      align-items: center;
      max-width: 100%;
      max-height: 120px;
      overflow: hidden;
      position: relative;
      h1 {
        font-size: 35px;
        font-weight: 500;
        padding-left: 2rem;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .container {
      padding: 5rem 1rem;
      h1 {
        font-size: 30px;
      }
    }
    .work {
      align-items: center;
      width: 100%;
      max-height: 120px;
      overflow: hidden;
      position: relative;
      h1 {
        font-size: 20px;
        font-weight: 500;
        padding-left: 2rem;
      }
    }

    .arrow {
      max-width: 80px;
    }

    .detail {
      margin-top: 0;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      div {
        padding-bottom: 2rem;
        row-gap: 1.5rem;
        h2 {
          font-size: 20px;
          font-weight: 400;
        }
        p {
          font-weight: 300;
          font-size: 18px;
          line-height: 30px;
        }
      }
    }
  }
`;
