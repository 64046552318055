import React from "react";
import styled from "styled-components";
// import team1 from "../assets/images/team/team1.webp";
import team2 from "../assets/images/team/team2.webp";
import team3 from "../assets/images/team/team3.webp";
// import team4 from "../assets/images/team/team4.webp";

const Team = () => {
  return (
    <TeamStyle>
      <div className="container">
        <h1>Team</h1>
        <div className="members">
          {/* <div className="member">
            <div className="image">
              <img src={team1} alt="member" />
            </div>
            <div className="info">
              <h1>DIRECTOR</h1>
              <p>Engr. Dr. Md. Kharshiduzzaman</p>
              <p>B.Sc (Mech, BUET)</p>
              <p>M.Sc & PhD (Mech., Italy)</p>
              <p>MIEB</p>
            </div>
          </div> */}

          {/* <div className="member">
            <div className="image">
              <img src={team4} alt="member" />
            </div>
            <div className="info">
              <h1>DIRECTOR</h1>
              <p>Engr. Md. Istiaque Hasan</p>
              <p>B.Sc (Mech, BUET)</p>
            </div>
          </div> */}

          <div className="member">
            <div className="image">
              <img src={team2} alt="member" />
            </div>
            <div className="info">
              <h1>CHAIRMAN</h1>
              <p>Md. Humayun Kabir</p>
            </div>
          </div>

          <div className="member">
            <div className="image">
              <img src={team3} alt="member" />
            </div>
            <div className="info">
              <h1>HEAD OF OPERATION</h1>
              <p>Engr. Md. Masudur Rahman</p>
            </div>
          </div>
        </div>
      </div>
    </TeamStyle>
  );
};

export default Team;

const TeamStyle = styled.div`
  .container {
    max-width: 1400px;
    /* min-height: 100vh; */
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 5rem 0;
    row-gap: 2rem;
    overflow: hidden;

    h1 {
      font-weight: 700;
      font-size: 72px;
    }
  }
  .members {
    display: flex;
    /* justify-content: space-between; */
    column-gap: 4rem;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .member {
    background: rgba(255, 255, 255, 0.17);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    padding: 1rem;
    min-width: 301px;
    max-width: 301px;
    .info {
      width: 100%;
    }
    h1 {
      font-weight: 700;
      font-size: 24px;
      padding: 1rem 0 0.5rem 0;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
    }
    .image {
      max-height: 250px;
      /* max-width: 200px; */
      width: 100%;
      overflow: hidden;
      border-radius: 10px;
    }
    img {
      border-radius: 10px;
      width: 100%;
      /* width: 200px; */
      /* max-height: 200px; */
    }
  }
  @media only screen and (max-width: 1400px) {
    .container {
      max-width: 1100px;
      padding: 5rem 0;
      row-gap: 2.5rem;

      h1 {
        font-weight: 700;
        font-size: 62px;
      }
    }

    .members {
      column-gap: 3rem;
    }

    .member {
      padding: 1.2rem;
      min-width: 301px;
      max-width: 301px;
      h1 {
        font-size: 25px;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .container {
      min-height: 50vh;
      padding: 2rem 1rem 8rem 1rem;

      row-gap: 4rem;
      h1 {
        font-weight: 700;
        font-size: 30px;
      }
    }

    .members {
      column-gap: 1.7rem;
      overflow-x: scroll;
    }

    .member {
      h1 {
        font-size: 24px;
      }
    }
  }
`;
