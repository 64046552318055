import React from "react";
import styled from "styled-components";
import bgImage from "../assets/images/story/bg.webp";
import { Link } from "react-router-dom";

const StoryBanner = () => {
  return (
    <BannerStyle>
      <div className="text-container">
        <h1>OUR STORY</h1>
        <p>
          Thank you for considering AirCon for your air conditioning,
          dehumidification, fabrication, and construction needs. We look forward
          to the opportunity to serve you and help you succeed in a changing
          world.
        </p>
        <Link to="/portfolio">
          <button>View Works</button>
        </Link>
      </div>
      <div className="overlay"></div>
    </BannerStyle>
  );
};

export default StoryBanner;

const BannerStyle = styled.div`
  background-image: url(${bgImage});
  min-height: 81vh;
  width: 100%;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      108.99deg,
      rgba(0, 0, 0, 0.46) 67.18%,
      rgba(217, 217, 217, 0) 100%
    );
    z-index: 2;
  }
  .text-container {
    z-index: 3;
    color: #ffffff;
    width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    h1 {
      font-weight: 700;
      font-size: 72px;
      line-height: 90px;
    }
    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 35px;
      width: 48%;
    }
    button {
      width: fit-content;
      margin-top: 2rem;
      background-color: #d9190b;
      border: none;
      padding: 1.2rem 4rem;
      color: #ffffff;
      font-weight: 700;
      font-size: 24px;
      cursor: pointer;
    }
  }
  @media only screen and (max-width: 1400px) {
    .text-container {
      max-width: 1100px;
      row-gap: 1rem;
      h1 {
        font-weight: 700;
        font-size: 62px;
        line-height: 90px;
      }
      p {
        font-weight: 400;
        font-size: 20px;
        line-height: 35px;
        width: 60%;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    background-position: center;
    .overlay {
      background: linear-gradient(
        108.99deg,
        rgba(0, 0, 0, 0.56) 80.18%,
        rgba(217, 217, 217, 0) 100%
      );
    }

    /* background-position: center; */
    height: 87.5vh;
    .text-container {
      padding: 0 1rem;
      h1 {
        font-size: 30px;
        line-height: 48px;
        /* text-align: center; */
        width: 100%;
      }
      p {
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;
        width: 100%;
      }
      button {
        margin-top: 2rem;

        padding: 1rem 3.5rem;
        color: #ffffff;
        font-weight: 700;
        font-size: 20px;
      }
    }
  }
`;
