import React, { useEffect } from "react";
import StoryBanner from "../components/StoryBanner";
import Values from "../components/Values";
import Mission from "../components/Mission";
import Research from "../components/Research";
import Strategy from "../components/Strategy";
import { useLocation } from "react-router-dom";

const Story = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <StoryBanner />
      <Values />
      <Mission />
      <Research />
      <Strategy />
    </div>
  );
};

export default Story;
