import React from "react";
import styled from "styled-components";
import service1 from "../assets/images/service/service1.svg";
import service2 from "../assets/images/service/service2.svg";
import service3 from "../assets/images/service/service3.svg";
import service4 from "../assets/images/service/service4.svg";

const Services = () => {
  return (
    <ServiceStyle id="service-section">
      <h1>
        Discover how we can help you save money on your energy bills with our
        energy-efficient HVAC solutions!
      </h1>
      <div className="services">
        <div className="service">
          <img src={service1} alt="service" />
          <h1>Design</h1>
        </div>
        <div className="service">
          <img src={service2} alt="service" />
          <h1>Supply</h1>
        </div>
        <div className="service">
          <img src={service3} alt="service" />
          <h1>Installation</h1>
        </div>
        <div className="service">
          <img src={service4} alt="service" />
          <h1>Supervision & Commissioning</h1>
        </div>
      </div>
      <div className="service-details">
        <div className="item">
          <p>All Kind of Air Conditioning System</p>
        </div>
        <div className="item">
          <p>Central Air Conditioning System</p>
        </div>
        <div className="item">
          <p>Precision Type Air conditioner for the switch room & IT room</p>
        </div>
        <div className="item">
          <p>
            Dehumidification and Air conditioning system for Pharmaceuticals
          </p>
        </div>
        <div className="item">
          <p>Garments Industry and others process industries and Ducting</p>
        </div>
        <div className="item">
          <p>
            Piping and others Fabrication job for the Textiles & Pharmaceuticals
            Industries and for Comfort Sectors
          </p>
        </div>
        <div className="item">
          <p>
            Fabrication and installation of different type of metal works
            including ducting and piping of the Factory
          </p>
        </div>
        <div className="item">
          <p>Design & Construction of Clean Room</p>
        </div>
        <div className="item">
          <p>Waste Heat Recovery System from Gas Generators</p>
        </div>
        <div className="item">
          <p>Co-generation, Exhaust & Jacket Heat Recovery</p>
        </div>
        <div className="item">
          <p>Cold room</p>
        </div>
        <div className="item">
          <p>Evaporative Cooling System</p>
        </div>
        <div className="item">
          <p>Carbon Di Oxide Sensor System.</p>
        </div>
      </div>
    </ServiceStyle>
  );
};

export default Services;

const ServiceStyle = styled.div`
  max-width: 1400px;
  margin: auto;
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
  padding: 6rem 0;
  overflow: hidden;
  h1 {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    width: 78%;
    color: #ffffff;
  }
  .services {
    display: flex;
    justify-content: space-between;
  }
  .service {
    background-color: #ffd31a;
    min-width: 280px;
    max-width: 280px;
    min-height: 240px;
    max-height: 240px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
    h1 {
      color: #000000;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
    }
  }

  .service-details {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .item {
    background-color: rgba(255, 255, 255, 0.17);
    padding: 0.5rem 1rem;
    color: #ffffff;
    width: fit-content;
    border-radius: 5px;
  }
  /* .item:nth-child(2n + 1) {
    background-color: #ffd31a;
  } */

  @media only screen and (max-width: 1400px) {
    max-width: 1100px;
    h1 {
      font-size: 30px;
      line-height: 48px;
      width: 78%;
      color: #ffffff;
    }
    .service {
      min-width: 250px;
      max-width: 250px;
      min-height: 240px;
      max-height: 240px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 1rem;
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 5rem 1rem;
    h1 {
      font-weight: 300;
      font-size: 30px;
      line-height: 40px;
      width: 100%;
    }

    .services {
      flex-direction: row;
      column-gap: 2rem;
      overflow: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
    }
    .service {
      margin: auto;
    }
  }
`;
