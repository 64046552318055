import React from "react";
import styled from "styled-components";
import valueImage from "../assets/images/story/value.svg";

const Values = () => {
  return (
    <ValueStyle>
      <div className="container">
        <h1>Our Values</h1>
        <div className="text-container">
          <img src={valueImage} alt="values" />
          <div className="text">
            <p className="des">
              Our corporate identity defines the kind of company we are now and
              the one we needto be in the future. Central to that identity is a
              commitment to create ways to helpcustomers thrive in a changing
              world. We are committed to contributing positively to society and
              to a sustainable future. This is part of the heart of AIRCON
              Engineering Ltd. To do this we must live our brand values:
            </p>

            <div className="values">
              <li>
                We do what we say we will - <span>Trustworthy</span>
              </li>
              <li>
                We work as one team - <span>Helpful</span>
              </li>
              <li>
                We create new possibilities - <span>Inspiring</span>
              </li>
              <li>
                We make things clear - <span>Straightforward</span>
              </li>
              <li>
                We believe in what we do - <span>Heart</span>
              </li>
            </div>
          </div>
        </div>
      </div>
    </ValueStyle>
  );
};

export default Values;

const ValueStyle = styled.div`
  .container {
    max-width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    row-gap: 5rem;
    padding: 5rem 0;
    h1 {
      font-weight: 700;
      font-size: 72px;
    }
  }
  .text-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text {
    width: 55%;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }
  .des {
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
  }

  .values {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    li {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      span {
        color: #f8c906;
        font-weight: 700;
      }
    }
  }

  @media only screen and (max-width: 1400px) {
    .container {
      max-width: 1100px;

      row-gap: 2.5rem;
      padding: 5rem 0;
      h1 {
        font-size: 62px;
      }
    }
    .des {
      font-weight: 400;
      font-size: 18px;
      line-height: 35px;
    }
    .values {
      row-gap: 0.5rem;
      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 35px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .container {
      padding: 5rem 1rem;
      h1 {
        font-weight: 700;
        font-size: 30px;
      }
    }
    .text-container {
      flex-direction: column;
      align-items: flex-start;
      img {
        width: 50%;
        /* margin: auto; */
        padding-bottom: 2rem;
      }
    }
    .text {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
    }
    .des {
      font-size: 18px;
    }

    .values {
      row-gap: 0.5rem;
      li {
        font-weight: 400;
        font-size: 18px;

        span {
          color: #f8c906;
          font-weight: 700;
          display: block;
        }
      }
    }
  }
`;
