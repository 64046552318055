import React from "react";
import styled from "styled-components";
import rearchImage from "../assets/images/story/research.svg";

const Research = () => {
  return (
    <ResearchStyle>
      <div className="container">
        <h1>Research & Development </h1>
        <div className="text-container">
          <img src={rearchImage} alt="values" />
          <div className="text">
            <p className="des">
              Research and Development is an inseparable part of our endeavor
              for providing need specific solution.
            </p>

            <div className="values">
              <p>
                We invest our experience and exposure in assuring that
                ourtechnology provides optimum service in the climatic condition
                of Bangladesh. AirconEngineering Ltd. service engineers are
                engaged in finding ways to offer most efficientsolution to our
                customers in the most cost effective way.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ResearchStyle>
  );
};

export default Research;

const ResearchStyle = styled.div`
  .container {
    max-width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    row-gap: 5rem;
    padding: 5rem 0;
    h1 {
      font-weight: 700;
      font-size: 72px;
    }
  }
  .text-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text {
    width: 55%;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }
  .des {
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
  }

  .values {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 40px;
      span {
        color: #f8c906;
        font-weight: 700;
      }
    }
  }

  @media only screen and (max-width: 1400px) {
    .container {
      max-width: 1100px;

      row-gap: 2.5rem;

      h1 {
        font-weight: 700;
        font-size: 62px;
      }
    }
    .des {
      font-size: 18px;
      line-height: 35px;
    }

    .values {
      row-gap: 0.5rem;
      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 35px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .container {
      padding: 5rem 1rem;
      h1 {
        font-weight: 700;
        font-size: 30px;
      }
    }
    .text-container {
      flex-direction: column;
      align-items: flex-start;
      img {
        width: 50%;
        padding-bottom: 2rem;
      }
    }
    .text {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
    }
    .des {
      font-size: 18px;
      line-height: 35px;
    }

    .values {
      row-gap: 1rem;
      p {
        font-weight: 400;
        font-size: 18px;
        span {
          color: #f8c906;
          font-weight: 700;
          display: block;
        }
      }
    }
  }
`;
