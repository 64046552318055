import React from "react";
import styled from "styled-components";
import MissionImage from "../assets/images/story/mission.svg";

const Mission = () => {
  return (
    <MissionStyle>
      <div className="container">
        <h1>Mission & Vision</h1>
        <div className="text-container">
          <img src={MissionImage} alt="mission" />
          <div className="text">
            <ul>
              <li>
                Will try to provide all types of air-conditioning solutions in
                the respective field.
              </li>
              <li>
                Customers will make us their first choice, viewing us as
                providing them the best value for money and as the easiest
                company in the country for them to do business with. Our culture
                will be innovative, fast-moving and entrepreneurial.
              </li>
              <li>Our employees are capable to provide a competitive edge.</li>
              <li>
                We will link our work to clear measures and consistently deliver
                our financial commitments.
              </li>
              <li>
                Provide 24x7 Customer Help Line (Expected Customer Satisfaction
                Rate 95%).
              </li>
            </ul>
          </div>
        </div>
      </div>
    </MissionStyle>
  );
};

export default Mission;

const MissionStyle = styled.div`
  .container {
    max-width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    row-gap: 5rem;
    padding: 5rem 0;
    h1 {
      font-weight: 700;
      font-size: 72px;
    }
  }
  .text-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .text {
    width: 55%;
    display: flex;
    flex-direction: column;
    row-gap: 4rem;
    ul {
      margin-left: 22px;
    }
    ul li {
      font-weight: 400;
      font-size: 20px;
      line-height: 35px;
    }
  }

  @media only screen and (max-width: 1400px) {
    .container {
      max-width: 1100px;
      row-gap: 2.5rem;
      h1 {
        font-size: 62px;
      }
    }

    .text {
      ul li {
        font-weight: 400;
        font-size: 18px;
        line-height: 35px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .container {
      padding: 5rem 1rem;
      h1 {
        font-weight: 700;
        font-size: 30px;
      }
    }
    .text-container {
      flex-direction: column;
      img {
        width: 50%;
        padding-bottom: 2rem;
      }
    }
    .text {
      width: 100%;
      display: flex;
      flex-direction: column;

      ul {
        margin-left: unset;
        padding-left: 1rem;
        li {
          font-weight: 400;
          font-size: 18px;
        }
      }
    }
  }
`;
