import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import brand1 from "../assets/images/products/brand1.png";
import brand2 from "../assets/images/products/brand2.png";
import brand3 from "../assets/images/products/brand3.png";
import brand4 from "../assets/images/products/brand4.png";
import brand5 from "../assets/images/products/brand5.png";
import brand6 from "../assets/images/products/brand6.png";
import brand7 from "../assets/images/products/brand7.png";
import brand8 from "../assets/images/products/brand8.png";
import brand9 from "../assets/images/products/brand9.png";
import product1 from "../assets/images/products/product1.png";
import product2 from "../assets/images/products/product2.png";
import product3 from "../assets/images/products/product3.png";
import product4 from "../assets/images/products/product4.png";
import product5 from "../assets/images/products/product5.png";
import product6 from "../assets/images/products/product6.png";
import product7 from "../assets/images/products/product7.png";
import product8 from "../assets/images/products/product8.png";
import product9 from "../assets/images/products/product9.png";
import product10 from "../assets/images/products/product10.png";
import product11 from "../assets/images/products/product11.png";

export const Products = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <ProductStyle>
      <div className="container">
        <h1>Products</h1>
        <div className="products">
          <div className="product">
            <div className="top">
              <p>V.R.F and Absorption Chiller System</p>
              <img src={brand1} alt="brand logo" />
            </div>
            <div className="bottom">
              <img src={product1} alt="product" />
            </div>
          </div>
          <div className="product">
            <div className="top">
              <p>Chiller & AHU</p>
              <img src={brand2} alt="brand logo" />
            </div>
            <div className="bottom">
              <img src={product2} alt="product" />
            </div>
          </div>
          <div className="product">
            <div className="top">
              <p>Johnson Controls</p>
              <img src={brand3} alt="brand logo" />
            </div>
            <div className="bottom">
              <img src={product3} alt="product" />
            </div>
          </div>
          <div className="product">
            <div className="top">
              <p>Chiller & AHU</p>
              <img src={brand4} alt="brand logo" />
            </div>
            <div className="bottom">
              <img src={product4} alt="product" />
            </div>
          </div>
          <div className="product">
            <div className="top">
              <p>Cold Room Solution</p>
              <img src={brand1} alt="brand logo" />
            </div>
            <div className="bottom">
              <img src={product5} alt="product" />
            </div>
          </div>
          <div className="product">
            <div className="top">
              <p>Precision Type Air-conditioner</p>
              <img src={brand5} alt="brand logo" />
            </div>
            <div className="bottom">
              <img src={product6} alt="product" />
            </div>
          </div>
          <div className="product">
            <div className="top">
              <p>Evaporative cooling System</p>
              <img src={brand6} alt="brand logo" />
            </div>
            <div className="bottom">
              <img src={product7} alt="product" />
            </div>
          </div>
          <div className="product">
            <div className="top">
              <p>Carbon Di Oxide Sensor</p>
              <img src={brand7} alt="brand logo" />
            </div>
            <div className="bottom">
              <img src={product8} alt="product" />
            </div>
          </div>
          <div className="product">
            <div className="top">
              <p>Ventilation System</p>
              <img src={brand8} alt="brand logo" />
            </div>
            <div className="bottom">
              <img src={product9} alt="product" />
            </div>
          </div>
          <div className="product">
            <div className="top">
              <p>Ventilation System</p>
              <img src={brand9} alt="brand logo" />
            </div>
            <div className="bottom">
              <img src={product10} alt="product" />
            </div>
          </div>
          <div className="product">
            <div className="top">
              <p>Air Purifier</p>
              <img src={brand1} alt="brand logo" />
            </div>
            <div className="bottom">
              <img src={product11} alt="product" />
            </div>
          </div>
        </div>
      </div>
    </ProductStyle>
  );
};

const ProductStyle = styled.div`
  .container {
    max-width: 1200px;
    margin: auto;
    padding: 5rem 0;
    h1 {
      font-weight: 700;
      font-size: 72px;
      padding-bottom: 5rem;
    }
  }

  .products {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 4rem;
  }

  .product {
    background-color: #ffffff;
    border-radius: 10px;
    width: 350px;
    max-height: 280px;
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
  }

  .top {
    p {
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 10px;
    }
    img {
      width: 150px;
      max-height: 50px;
    }
  }
  .bottom {
    display: flex;
    align-items: flex-end;
    justify-content: right;
    /* img {
      width: 180px;
      max-height: 150px;
    } */
  }

  .product:nth-child(1) {
    .bottom {
      img {
        width: 50%;
      }
    }
  }
  .product:nth-child(2) {
    .bottom {
      img {
        width: 55%;
      }
    }
  }

  .product:nth-child(3) {
    .top {
      img {
        width: 140px;
      }
    }
    .bottom {
      img {
        width: 50%;
      }
    }
  }

  .product:nth-child(4) {
    .top {
      img {
        width: 130px;
      }
    }
    .bottom {
      img {
        width: 60%;
      }
    }
  }
  .product:nth-child(5) {
    .bottom {
      img {
        width: 70%;
      }
    }
  }
  .product:nth-child(6) {
    .top {
      img {
        width: 100px;
      }
    }
    .bottom {
      img {
        width: 60%;
      }
    }
  }
  .product:nth-child(7) {
    .bottom {
      img {
        width: 50%;
      }
    }
  }
  .product:nth-child(8) {
    .top {
      img {
        width: 80px;
      }
    }
    .bottom {
      img {
        width: 35%;
      }
    }
  }
  .product:nth-child(9) {
    .top {
      img {
        width: 70px;
        min-height: 70px;
      }
    }
    .bottom {
      img {
        width: 50%;
      }
    }
  }
  .product:nth-child(10) {
    .top {
      img {
        width: 70px;
      }
    }
    .bottom {
      img {
        width: 45%;
      }
    }
  }
  .product:nth-child(11) {
    .bottom {
      img {
        width: 40%;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .container {
      padding: 2rem 1rem;
      h1 {
        font-size: 30px;
        padding-bottom: 2rem;
      }
    }
    .products {
      display: flex;
      justify-content: center;
      gap: 2rem;
    }
  }
`;
